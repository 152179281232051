'use client'
import bg from './Images/bg.svg'
import React, { useState, useRef, useEffect } from 'react'
import logo from './Images/logo.svg'
import phone from './Images/phone.svg'
import background from './Images/background.svg'
import image from './Images/image.svg'
import image2 from './Images/image2.svg'
import image3 from './Images/image3.svg'
import image4 from './Images/image4.svg'
import image5 from './Images/image5.svg'
import image6 from './Images/image6.svg'
import logo2 from './Images/logo2.svg'
import line from './Images/line.svg'
import footer from './Images/footer.svg'
import secondSection from './Images/secondSection.svg'
import curvedLine from './Images/curveLine.svg'
import twitter from './Images/twitter.svg'
import linkedin from './Images/linkedin.svg'
import handShake from './Images/handShake.svg'
import Shape from './Images/Shape.svg'
import Wave from './Images/wave.svg'
import { useRouter } from 'next/navigation'
import Image from 'next/image'
import { X as CloseIcon, Menu as MenuIcon } from 'react-feather'
import './Home.css'
import Link from 'next/link'
function Home() {
    const [menuOpen, setMenuOpen] = useState(false)
    const [scrolled, setScrolled] = useState(false)
    const footerRef = useRef(null)
    const router = useRouter()
    const toggleMenu = () => setMenuOpen(!menuOpen)
    const scrollToFooter = () => {
        footerRef.current?.scrollIntoView({ behavior: 'smooth' })
        setMenuOpen(false)
    }
    const handleStart = () => {
        router.push('/UserInfo')
    }

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setScrolled(true)
        } else {
            setScrolled(false)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    return (
        <div className="relative ">
            {/* Navbar Section */}
            <div
                className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${
                    scrolled ? 'bg-white shadow-lg' : 'bg-[#0C0E25]'
                }`}
            >
                <div
                    className={`w-full p-4 px-[20px] md:px-[50px] lg:px-[100px] py-4 flex items-center justify-between transition-all duration-300 ${
                        scrolled ? 'text-black' : 'text-white'
                    }`}
                >
                    <Image
                        src={scrolled ? logo2 : logo}
                        alt="logo"
                        className="w-[120px] md:w-[163px] h-auto"
                    />
                    <div className="md:hidden flex items-center">
                        <button
                            onClick={toggleMenu}
                            className="text-inherit text-2xl"
                            aria-label="Toggle menu"
                        >
                            <MenuIcon />
                        </button>
                    </div>
                    <div className="hidden md:flex md:gap-6">
                        <Link href="#home" className="relative group">
                            Home
                            <span className="absolute bottom-0 left-0 right-0 h-[2px] bg-gradient-to-r from-[#FF343E] to-[#1D58BA] scale-x-0 transition-transform duration-300 group-hover:scale-x-100" />
                        </Link>
                        <Link href="#about" className="relative group">
                            About
                            <span className="absolute bottom-0 left-0 right-0 h-[2px] bg-gradient-to-r from-[#FF343E] to-[#1D58BA] scale-x-0 transition-transform duration-300 group-hover:scale-x-100" />
                        </Link>
                        <Link href="#features" className="relative group">
                            Features
                            <span className="absolute bottom-0 left-0 right-0 h-[2px] bg-gradient-to-r from-[#FF343E] to-[#1D58BA] scale-x-0 transition-transform duration-300 group-hover:scale-x-100" />
                        </Link>
                        <Link href="#contact" className="relative group">
                            Contact
                            <span className="absolute bottom-0 left-0 right-0 h-[2px] bg-gradient-to-r from-[#FF343E] to-[#1D58BA] scale-x-0 transition-transform duration-300 group-hover:scale-x-100" />
                        </Link>
                    </div>
                    <div className="hidden md:flex w-[134px] h-[40px] rounded justify-center items-center gap-2 relative bg-[#FF343E] border-2 border-transparent transition duration-300 ease-in-out hover:bg-gradient-to-r hover:from-[#FF343E] hover:to-[#1D58BA] hover:border-white">
                        <Link
                            href="/otplogin"
                            className="flex items-center justify-center w-full h-full relative z-10"
                        >
                            <Image src={phone} alt="phone" className="mr-2" />
                            <span className="text-white font-semibold text-sm">
                                Login
                            </span>
                        </Link>
                        <div className="absolute inset-0 rounded-md border-gradient-to-r from-[#FF343E] to-[#1D58BA] opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100"></div>
                    </div>
                </div>

                {/* Mobile Menu */}
                {menuOpen && (
                    <div className="md:hidden fixed top-0 left-0 w-full h-screen bg-[#0C0E25] flex flex-col items-center justify-center z-50">
                        <button
                            onClick={toggleMenu}
                            className="absolute top-4 right-4 text-white text-3xl"
                            aria-label="Close menu"
                        >
                            <CloseIcon />
                        </button>
                        <a
                            href="#about"
                            className="text-white text-lg mb-4"
                            onClick={scrollToFooter}
                        >
                            About
                        </a>
                        <a
                            href="#features"
                            className="text-white text-lg mb-4"
                            onClick={scrollToFooter}
                        >
                            Features
                        </a>
                        <a
                            href="#contact"
                            className="text-white text-lg mb-4"
                            onClick={scrollToFooter}
                        >
                            Contact
                        </a>
                        <div className="flex items-center justify-center w-[134px] h-[40px] rounded bg-[#FF343E] border-[#FF343E] mt-4">
                            <Image src={phone} alt="phone" />
                            <Link href="/otplogin">
                                <button className="text-white font-semibold text-sm ml-2">
                                    Login
                                </button>
                            </Link>
                        </div>
                    </div>
                )}
            </div>

            {/* Text Section */}
            <div
                id="home"
                ref={footerRef}
                className="bg-[#0C0E25] flex flex-col sm:flex-row items-center justify-center text-center relative z-10 px-4 bg-cover bg-center"
            >
                <div className="p-8 rounded-lg mt-4 ">
                    <h2 className="font-bold text-[42px] md:text-[62px] text-white">
                        AI-enabled platform for
                        <br />
                        <span className="typewriter">
                            <span>m</span>
                            <span>o</span>
                            <span>d</span>
                            <span>e</span>
                            <span>r</span>
                            <span>n</span>
                            <span className="gap"> </span> <span>l</span>
                            <span>e</span>
                            <span>a</span>
                            <span>d</span>
                            <span>e</span>
                            <span>r</span>
                            <span className="relative">
                                <span>s</span>
                                <Image
                                    width={30}
                                    height={25}
                                    src={Wave}
                                    alt="Icon"
                                    className="absolute  right-[-5px] top-[0px] md:top-[2px] lg:top-[10px] transform"
                                />
                            </span>
                        </span>
                    </h2>

                    <div className="mt-4 max-w-lg mx-auto ">
                        <p className="text-[#EDEFFF] text-[16px] font-interRegular leading-[30px]">
                            Our advanced technology and innovative tools help
                            you connect with voters streamline operations and
                            expand your campaigns reach.
                        </p>
                    </div>

                    <div className="relative mt-[20px] flex items-center justify-center">
                        <button
                            onClick={handleStart}
                            className="w-[160px] h-[48px] cursor-pointer text-[#0C0E25] text-[14px] font-semibold bg-[#FFFFFF] rounded-md transition duration-300 ease-in-out hover:bg-gradient-to-r hover:from-[#FF343E] hover:to-[#1D58BA] hover:text-white"
                        >
                            Request a Demo
                        </button>
                        <Image
                            width={70}
                            height={75.65}
                            src={Shape}
                            alt="Demo Image"
                            className="absolute right-[-1px] sm:right-[100px] md:right-[200px] lg:right-[200px] top-1/4 transform -translate-y-1/2"
                        />
                    </div>
                </div>
            </div>

            {/*Good Experience*/}
            <div
                id="about"
                className="w-full px-6 md:px-0 flex flex-col items-center relative h-[450px]  sm:mt-0 sm:h-[748px] md:h-[808px]"
            >
                {/* <Image
                    alt="image"
                    src={secondSection}
                    width={1170}
                    height={692}
                    className="absolute z-20 bottom-1/2 sm:bottom-1/3 md:bottom-1/3"
                /> */}
                <Image
                    alt="image"
                    className="absolute z-20 lg:w-[1030px]  h-[340px] sm:h-[646.77px] bottom-1/2 sm:bottom-1/3 md:bottom-1/3 "
                    src={secondSection}
                />

                <div className="absolute gap-4 md:px-7 flex flex-col md:flex-row justify-center items-center bottom-14 md:py-7  w-full lg:w-[1000px] h-[210px] sm:h-[186px] border border-[#E2E2E2]">
                    <div className="w-full  flex justify-center md:block md:w-[430px]">
                        <p className="text-[18px] text-center md:text-left sm:text-[24px] md:text-[27px] font-bold">
                            Engage minds, gain support: The smarter path to
                            victory
                        </p>
                    </div>
                    <div className="flex flex-col gap-4 w-full items-center  md:block  md:w-[441px] md:gap-5">
                        <p className="font-normal w-full text-center md:text-left text-[12px] md:text-[16px] text-[#0C0E25]">
                            {/* The T9L Qube startup studio model has demonstrably flattened the
              early stage startup failure curve and made startup success
              predictable */}
                            Candidate.live is an AI-powered platform that
                            transforms political campaigns with real-time
                            insights, streamlined management, and personalized
                            outreach all in one place.
                        </p>
                        <a
                            href="#"
                            className="underline underline-offset-2 text-[14px] font-bold"
                        >
                            More About Us
                        </a>
                    </div>
                </div>
            </div>
            {/*Features*/}
            <div
                id="features"
                ref={footerRef}
                className="w-full py-16 bg-[#F2F7FF] flex flex-col justify-center items-center"
            >
                <h1 className="text-[32px] text-center font-bold text-[#111111]  mb-8">
                    Product’s features
                </h1>
                <div className="w-full max-w-[1070px] h-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ">
                    {[
                        {
                            src: image,
                            title: 'Constituency mapping',
                            description:
                                'Visualize and analyze key voter demographics in each constituency. Strategically plan outreach to maximize your campaign’s effectiveness.',
                        },
                        {
                            src: image2,
                            title: 'Task manager',
                            description:
                                'Easily assign, track, and manage campaign tasks. Ensure smooth execution with a collaborative task management system.',
                        },
                        {
                            src: image3,
                            title: 'Broadcasting',
                            description:
                                'Craft compelling campaign messages with AI-driven insights. Tailor narratives that resonate with specific voter segments.',
                        },
                        {
                            src: image4,
                            title: 'Scheduling',
                            description:
                                'Automate your outreach with a smart scheduler for emails, texts, and social media. Ensure timely communication with voters based on their activity patterns.',
                        },
                        {
                            src: image5,
                            title: 'All-in-one dashboard',
                            description:
                                'Monitor your entire campaign from one unified dashboard. Get real-time updates on voter engagement, tasks, and team performance',
                        },
                        {
                            src: image6,
                            title: 'Team chat',
                            description:
                                'Facilitate seamless communication within your campaign team. Collaborate in real time through an integrated, secure chat platform.',
                        },
                    ].map((feature, index) => (
                        <div
                            key={index}
                            className="flex flex-col items-center justify-center bg-[#FFFFFF] border border-[#E5E5E5] w-full h-[250px] sm:h-[300px] md:h-[350px] p-4"
                        >
                            <Image
                                src={feature.src}
                                alt={feature.title}
                                className="w-[62px] h-[60px]"
                            />
                            <h2 className="text-[#111111] font-bold mt-4 text-center">
                                {feature.title}
                            </h2>
                            <p className="text-[#444444] text-center font-normal w-full max-w-[300px] font-interRegular text-[15px] mt-2">
                                {feature.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>

            {/*Work with us div*/}
            <div className="w-full bg-white h-[450px] flex justify-center items-center ">
                <div className="relative flex gap-4 h-auto bg-[#FFD338] border border-[#E2E2E2] w-full  lg:w-[1030px] ">
                    {/* Handshake Image */}
                    <Image
                        alt="image"
                        src={handShake}
                        className="w-[180px] h-[120px] sm:w-[200px] sm:h-[180px] md:w-[302px] md:h-[200px]"
                        width={180}
                        height={120}
                    />

                    {/* Text and Button */}
                    <div className="flex flex-col justify-center py-4 max-w-full gap-4">
                        <p className="text-[20px] sm:text-[26px]  md:text-[32px] font-extrabold">
                            Be the catalyst of change
                        </p>
                        <p className="text-[12px] sm:text-[14px] md:text-[16px] break-words font-normal text-[#222222] w-full sm:w-[300px] md:w-[420px]  lg:w-[506px] ">
                            Join Candidate.live - where talent meets purpose to
                            drive the impact on democracy you want to see, with
                            innovative AI tools build by you.
                        </p>
                        <button
                            onClick={handleStart}
                            className="w-[130px] sm:w-[140px] md:w-[160px] bg-white text-[12px] sm:text-[14px] font-semibold h-[40px] sm:h-[44px] md:h-[48px] rounded-md transition duration-300 ease-in-out hover:bg-gradient-to-r hover:from-[#FF343E] hover:to-[#1D58BA] hover:text-white"
                        >
                            Request a Demo
                        </button>
                    </div>

                    {/* Curved Line */}
                    <Image
                        alt="image"
                        src={curvedLine}
                        className="hidden md:block w-[140px] md:w-[185.07px] absolute bottom-0 right-0 lg:block"
                    />
                </div>
            </div>

            {/* Footer Section */}
            <div
                id="contact"
                ref={footerRef}
                className="relative w-full bg-[#ECF3FE] py-20 h-[520px] md:h-[360px] "
            >
                {/* Background Image */}
                <div className=" flex absolute bottom-0 w-full overflow-x-hidden z-0">
                    <Image
                        src={footer}
                        alt="footer-bg"
                        className="object-cover"
                        width={1128.5}
                        height={107}
                    />

                    <Image
                        src={footer}
                        alt="footer-bg"
                        className=" object-cover"
                        width={1128.5}
                        height={107}
                    />
                </div>

                {/* Text Content */}

                <div className="relative z-10 w-10/12 mx-auto flex flex-col md:flex-row gap-[100px] lg:gap-[250px] md:items-start">
                    <div className="flex flex-col items-center md:items-start mb-8 md:mb-0">
                        <Image
                            src={logo2}
                            alt="logo2"
                            className=""
                            width={163}
                            height={20}
                        />
                        <div className="text-center md:text-left mt-2 px-4 sm:px-0">
                            <p className="text-[#444444] w-full sm:w-[300px] text-[14px]">
                                @Polity Tech Solutions Pvt. Ltd
                            </p>
                            <p className="text-[#444444] mt-2 text-[14px]">
                                All Rights Reserved
                            </p>
                            <p className="text-[#444444] mt-2 text-[14px]">
                                J K-247-D DILSHAD GARDEN, ATM CORPRATION,
                            </p>
                            <p className="text-[#444444] mt-2 text-[14px]">
                                Shahdara, Shahdara, Shahdara- 110095, Delhi
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-row justify-around md:justify-between w-full md:w-auto gap-[50px] items-start text-center md:text-left">
                        {/* Menu Section */}

                        <div className="flex flex-col items-center md:items-start mb-8 md:mb-0">
                            {/* <img src={line} alt="line" className="hidden md:block mb-2" /> */}
                            <div>
                                <Link
                                    href="#home"
                                    className="relative text-[#444444] text-[14px] block mb-1 group"
                                >
                                    Home
                                    <span className="absolute bottom-0 left-0 right-0 h-[2px] bg-gradient-to-r from-[#FF343E] to-[#1D58BA] scale-x-0 transition-transform duration-300 group-hover:scale-x-100" />
                                </Link>
                                <Link
                                    href="#about"
                                    className="relative text-[#444444] text-[14px] block mb-1 group"
                                >
                                    About
                                    <span className="absolute bottom-0 left-0 right-0 h-[2px] bg-gradient-to-r from-[#FF343E] to-[#1D58BA] scale-x-0 transition-transform duration-300 group-hover:scale-x-100" />
                                </Link>
                                <Link
                                    href="#features"
                                    className="relative text-[#444444] text-[14px] block group"
                                >
                                    Features
                                    <span className="absolute bottom-0 left-0 right-0 h-[2px] bg-gradient-to-r from-[#FF343E] to-[#1D58BA] scale-x-0 transition-transform duration-300 group-hover:scale-x-100" />
                                </Link>
                            </div>
                        </div>

                        <div className="flex flex-col items-center md:items-start mb-8 md:mb-0">
                            {/* <img src={line} alt="line" className="hidden md:block mb-2" /> */}
                            <div>
                                <Link href="privacy-policy">
                                    <p className="text-[#444444] hover:cursor-pointer text-[14px] block mb-1">
                                        Privacy Policy
                                    </p>
                                </Link>
                                <p className="text-[#444444] hover:cursor-pointer text-[14px] block">
                                    Terms and Conditions
                                </p>
                            </div>
                        </div>

                        {/* Call Section */}
                        <div className="flex flex-col gap-4 items-center md:items-start">
                            {/* <img src={line} alt="line" className="hidden md:block mb-2" /> */}

                            <a
                                href="https://www.linkedin.com/company/candidate-live/about/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image
                                    src={linkedin}
                                    alt="LinkedIn"
                                    className="hover:cursor-pointer "
                                    width={16}
                                    height={16}
                                />
                            </a>
                            <Image
                                alt="twitter"
                                src={twitter}
                                className="hover:cursor-pointer "
                                width={16}
                                height={16}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home

// 'use client'
// import React, { useState, useEffect } from 'react'
// import axios from 'axios'

// const Home = () => {
//     const [score, setScore] = useState(7) // Initial score set to 1

//     // Fetch score from API
//     useEffect(() => {
//         // Replace with your API endpoint
//         axios
//             .get('https://api.example.com/score')
//             .then((response) => {
//                 setScore(response.data.score) // Assuming the API returns { score: <number> }
//             })
//             .catch((error) => console.error('Error fetching score:', error))
//     }, [])

//     // Calculate color based on score for the triangle
//     const getColorForScore = (score) => {
//         if (score <= 3) return 'red'
//         if (score <= 6) return 'yellow'
//         return 'green'
//     }

//     return (
//         <div className="flex flex-col items-center w-full max-w-md mx-auto p-4">
//             <div className="relative w-full h-8 rounded-full overflow-hidden">
//                 {/* Background Gradient */}
//                 <div
//                     className="absolute inset-0 h-full rounded-full transition-all duration-300"
//                     style={{
//                         background: `linear-gradient(to right, red, yellow, green)`,
//                     }}
//                 />

//                 {/* Triangle Pointer */}
//                 <div
//                     className="absolute top-0 h-full flex items-center justify-center transition-transform duration-300"
//                     style={{
//                         left: `${(score - 1) * 10}%`, // Calculate position based on score
//                     }}
//                 >
//                     <div
//                         className=" w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8"
//                         // style={{ borderBottomColor: getColorForScore(score) }}
//                     ></div>
//                 </div>
//             </div>

//             {/* Score Labels */}
//             <div className="flex justify-between w-full mt-2">
//                 {[...Array(10).keys()].map((num) => (
//                     <span
//                         key={num + 1}
//                         className={`text-xs ${
//                             num + 1 === score
//                                 ? 'font-bold text-gray-800'
//                                 : 'text-gray-500'
//                         }`}
//                     >
//                         {num + 1}
//                     </span>
//                 ))}
//             </div>
//         </div>
//     )
// }

// export default Home

// import React from 'react'

// interface ProgressBarProps {
//     percentage: number
// }

// const Home: React.FC<ProgressBarProps> = ({ percentage }) => {
//     const getLevel = () => {
//         if (percentage >= 90) return 'MAX'
//         if (percentage >= 70) return 'HIGH'
//         if (percentage >= 40) return 'MEDIUM'
//         if (percentage >= 10) return 'LOW'
//         return 'NO'
//     }

//     const getProgressColor = () => {
//         if (percentage >= 90) return 'bg-green-500'
//         if (percentage >= 70) return 'bg-lime-500'
//         if (percentage >= 40) return 'bg-yellow-400'
//         if (percentage >= 10) return 'bg-orange-500'
//         return 'bg-red-500'
//     }

//     return (
//         <div className="relative w-full h-10 bg-gray-200 rounded-full">
//             <div
//                 className={`absolute h-full rounded-full ${getProgressColor()}`}
//                 style={{ width: `${percentage}%` }}
//             ></div>
//             <div className="absolute top-[-2.5rem] left-[calc(95%-30px)] bg-green-500 text-white px-2 py-1 rounded-md">
//                 {percentage}%
//             </div>
//             <div className="flex justify-between items-center h-full text-white text-sm font-semibold">
//                 <div className="w-1/5 text-center bg-green-500 h-full rounded-l-full">
//                     MAX
//                 </div>
//                 <div className="w-1/5 text-center bg-lime-500 h-full">HIGH</div>
//                 <div className="w-1/5 text-center bg-yellow-400 h-full">
//                     MEDIUM
//                 </div>
//                 <div className="w-1/5 text-center bg-orange-500 h-full">
//                     LOW
//                 </div>
//                 <div className="w-1/5 text-center bg-red-500 h-full rounded-r-full">
//                     NO
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Home
